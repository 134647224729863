import React, {useContext, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import styled from 'styled-components';
import {Box} from "@qc-modules/components"
import AppContext from "../../context/AppContext";
import Deactivate from "../deactivate/Deactivate";
import Profile from "../profile2/Profile";
import {profileConstants} from "../util/appConstants2";
import useProfile from "../../hooks/useProfile2";
import {useDeactivate} from "../../hooks/useDeactivate";
import {isActiveTab, isValidImpersonation} from "../util/helper";
import {device} from '../../lib/breakpoints';

import {CollapsedContainer} from "./CollapsedContainer";
import {Products} from "../products/Products";
import {Preferences2} from "../preferences/Preferences2";
import {QueryClient, QueryClientProvider} from 'react-query'
import history from "../../context/History";
import authService from "../../services/authService";
import {addExtScript, getAdobeLaunchLibUrl, preHideAdobe} from "../../utils/Utils";

const StyledProfileContentBox = styled(Box)`
    margin-bottom: 90px;
    width: 100%;
`;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
});

export const logoutHandler = () => {
    history.push("/logout")
};

const UserDetails = () => {
    const {appStore} = useContext(AppContext);
    const isLaptop = useMediaQuery({query: device.laptop});
    const profileStore = useProfile();
    const deactivateStore = useDeactivate();
    deactivateStore.deactivateReasons = profileStore.dropDownsData && profileStore.dropDownsData.deactivateReasons;
    const tabAccess = appStore.tabAccess;
    const selector = "adobescriptprofile";

    const updateTabAccess = () => {
        let updatedTabAccess = {};
        appStore.setProfileTabPath(authService.isExternalUser()?profileConstants.APP_TABS_STATE.PROFILE_TAB.TAB_PATH:profileConstants.APP_TABS_STATE.PREFERENCES_TAB.TAB_PATH);
        updatedTabAccess[profileConstants.APP_TABS_STATE.PREFERENCES_TAB.ID] = profileStore && profileStore.userData && profileStore.userData.orgExportId !== 8888;
        updatedTabAccess[profileConstants.APP_TABS_STATE.PROFILE_TAB.ID] = authService.isExternalUser();
        updatedTabAccess[profileConstants.APP_TABS_STATE.PRODUCTS_TAB.ID] = profileStore && profileStore.userData && profileStore.userData.products && profileStore.userData.products.length;
        updatedTabAccess[profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.ID] = authService.isExternalUser() || (isValidImpersonation(appStore.impersonationStatus));
        appStore.setTabAccess({...tabAccess, ...updatedTabAccess});
    };

    useEffect(() => {
            preHideAdobe();
            const script = addExtScript(getAdobeLaunchLibUrl(), false, `#${selector}`, true, null);
            return () => {
                if (document.querySelector(`#${selector}`)) {
                    document.querySelector(`#${selector}`).removeChild(script);
                }
            }
    }, []);


    useEffect(() => {
        updateTabAccess()
        // eslint-disable-next-line
    }, [profileStore.userData]);

    useEffect(() => {
        const isExternalUser = authService.isExternalUser();
        if (appStore) {
            appStore.setIsInternalUser(!isExternalUser)
        }
    }, [appStore]);

    useEffect(() => {
        const impersonationStatus = authService.getImpersonationStatus();
        appStore.setImpersonationStatus(impersonationStatus)
        // eslint-disable-next-line
    }, []);
    const [tracked, setTracked] = useState(false)

    useEffect(() => {
        let visitor = {}
        if (!tracked && !isLaptop && profileStore.userData !== null && profileStore.userData !== undefined) {
            visitor = {
                'country_code': profileStore.userData.country_code ?? 'US',
                'org_id': profileStore.userData.org.orgId ?? '',
                'org_name': profileStore.userData.org.name ?? '',
                'qcguid': profileStore.userData.qcGuid ?? '',
                'user_name': profileStore.userData.username ?? '',
                'loggedIn': "logged_in",
                'user_domain': profileStore.userData.username && profileStore.userData.username.indexOf("@") > -1 ? profileStore.userData.username.slice(profileStore.userData.username.indexOf("@") + 1) : 'qualcomm.com'

            }
            if (isValidImpersonation(appStore.impersonationStatus)) {
                visitor['impersonating'] = true
                visitor['impersonator'] = appStore.impersonationStatus.impersonator
            }
            // trackPageLoadEvent('profile', 'UserDetails', '/login', visitor, {})
            window.pageChange('profile', 'UserDetails', 'login', visitor, {})
            setTracked(true);
        }

    }, [profileStore, appStore, tracked, isLaptop]);

    return (
        <QueryClientProvider client={queryClient}>
            <div id={selector}/>
            <StyledProfileContentBox>
                {profileStore && <Box>
                    {!isLaptop ?
                        <CollapsedContainer profileStore={profileStore} deactivateStore={deactivateStore}/> :
                        (
                            <Box>
                                {(isActiveTab(appStore, profileConstants.APP_TABS_STATE.PRODUCTS_TAB) && <Products
                                    store={profileStore}
                                />)}
                                {(isActiveTab(appStore, profileConstants.APP_TABS_STATE.PROFILE_TAB) && <Profile
                                    store={profileStore}
                                />)}
                                {(isActiveTab(appStore, profileConstants.APP_TABS_STATE.PREFERENCES_TAB) && profileStore.userData &&
                                    <Preferences2 userData={profileStore.userData}/>)}
                                {(isActiveTab(appStore, profileConstants.APP_TABS_STATE.DEACTIVATE_TAB) && <Deactivate
                                    appStore={appStore.profileTabPath === profileConstants.APP_TABS_STATE.PREFERENCES_TAB.MOBILE_VIEW.DESK_PATH}
                                    store={deactivateStore}
                                    userData={profileStore.userData}/>)}
                                {(isActiveTab(appStore, profileConstants.APP_TABS_STATE.LOGOUT_TAB) && !isValidImpersonation(appStore.impersonationStatus) && logoutHandler())}
                            </Box>
                        )
                    }
                </Box>
                }
            </StyledProfileContentBox>
        </QueryClientProvider>
    )
};
export default UserDetails;


